import classNames from 'clsx';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import ClampText from 'src/common/components/shared/ClampText';
import GridMatrixLayout from 'src/common/decorators/containerItems/GridMatrixLayout';
import { isMedium } from 'src/common/utils/breakpoints';
import GuideItemTitle from '../shared/GuideItemTitle';
import WithGuideItemActions from '../shared/WithGuideItemActions';
import css from './numbered-link.module.scss';

export function numberedMatrixGetNumberOfColumns(breakpoint) {
  if (isMedium(breakpoint)) return 2;
  return 1;
}

// This is in an effort to keep the spacing between sections uniform,
// as the margin bottom of the last child/children in the NumberLinkMatrix section adds an extra 16px of unecessary margin
// Function assumes a one OR two column NumberLinkMatrix structure
export function removeNumberedLinkMarginBottom(
  indexOfChild,
  numberOfColumns,
  numberOfChildren,
) {
  // If there is only 1 column, the last numberedLink shouldn't have margin-bottom.
  if (numberOfColumns === 1) {
    if (indexOfChild === numberOfChildren - 1) {
      return true;
    }

    return false;
  }

  // If there are two columns & number of children is even,
  // the last numberedLink, as well as the indexed numberedLink divided by 2, less 1 should not have margin bottom.
  if (numberOfChildren % 2 === 0) {
    if (
      indexOfChild === numberOfChildren - 1 ||
      indexOfChild === numberOfChildren / 2 - 1
    ) {
      return true;
    }

    return false;
  }

  // If there are two columns & number of children is uneven,
  // divide by 2, and use Math.floor on that value, that indexed numberedLink should not get margin-bottom.
  if (indexOfChild === Math.floor(numberOfChildren / 2)) {
    return true;
  }

  return false;
}

export const NumberedItemLink = ({
  guideItem,
  index,
  breakpoint,
  numLinksInMatrix,
  numColumnsInMatrix,
}) => {
  const title = get(guideItem, 'properties.seoInfo.title');

  const shouldRemoveMarginBottom = removeNumberedLinkMarginBottom(
    index,
    numColumnsInMatrix,
    numLinksInMatrix,
  );

  return (
    <div
      className={classNames(css.numberedLinkContainer, {
        [css.noMarginBottom]: shouldRemoveMarginBottom,
      })}
      data-testid={`guideItem-${index}`}
      data-testtype="numberedLink"
      data-nexttitle={title}
      data-nextguideitem={guideItem.guideId}
    >
      <div className={css.numberedLinkImageContainer}>
        <WithGuideItemActions guideItem={guideItem}>
          <img
            data-testid="guideItemImage"
            src={guideItem.image}
            alt={guideItem?.accessibilityTitle || guideItem?.title}
          />
        </WithGuideItemActions>
      </div>
      <div className={css.infoContainerWrapper}>
        <GuideItemTitle
          dataTestId="guideItemTitleLink"
          guideItem={guideItem}
          hasOwnTitleWrapper
          hasImage
        >
          <h2 data-testid="guideItemTitle" className={css.headerText}>
            {guideItem.title}
          </h2>
          <ClampText
            clamp={2}
            className={css.clampedDescriptionText}
            truncationHTML="<span>...</span>"
            breakpoint={breakpoint}
          >
            <div
              className={css.descriptionText}
              data-testid="guideItemDescription"
            >
              {guideItem.description}
            </div>
          </ClampText>
        </GuideItemTitle>
      </div>
    </div>
  );
};

NumberedItemLink.propTypes = {
  guideItem: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  breakpoint: PropTypes.number.isRequired,
  numLinksInMatrix: PropTypes.number.isRequired,
  numColumnsInMatrix: PropTypes.number.isRequired,
};

function NumberedLinkWithMatrixLayout(props) {
  return (
    <GridMatrixLayout
      ChildPresentation={NumberedItemLink}
      getNumberOfColumns={numberedMatrixGetNumberOfColumns}
      dataTestType="numberedListPresentation"
      {...props}
    />
  );
}

export default NumberedLinkWithMatrixLayout;
