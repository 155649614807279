import classNames from 'clsx';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import WideTextTile from '../childPresentations/WideTextTile';
import containerItemsCss from '../container-items.module.scss';
import Tile from '../gallery/childPresentations/Tile';
import Default from '../list/childPresentations/Default';
import ContainerTitle from '../shared/ContainerTitle';

const layoutMap = {
  WideTextTile: {
    Component: WideTextTile,
  },
  SquareTile: {
    Component: Tile,
    className: containerItemsCss.squareTileLayout,
  },
};

function getLayoutDetails(layout) {
  const { Component = Default, className = {} } = layoutMap[layout] || {};
  return { Component, className };
}

const Matrix = ({
  containerItem,
  id,
  isInterestSelector,
  getStyles,
  onItemClick,
}) => {
  const children = get(containerItem, 'children', []);

  // NOTE: Client needs to assume all children in a matrix have the same presentation layout
  const matrixLayout = children?.[0]?.presentation?.layout;
  const layoutDetails = getLayoutDetails(matrixLayout);
  const Item = layoutDetails.Component;
  const layoutContainerClassName = layoutDetails.className;

  return (
    <div
      id={id}
      data-testid={id}
      data-testtype="matrixLayout"
      data-testcount={children.length}
    >
      <ContainerTitle
        containerItem={containerItem}
        getStyles={getStyles}
        className={containerItemsCss.contentSubHeader}
      />
      <div
        data-testid="containerGuideItemsContainer"
        className={classNames(
          containerItemsCss.matrixContainer,
          isInterestSelector
            ? containerItemsCss.fixedSquareTileLayout
            : layoutContainerClassName,
        )}
      >
        {children.map((child, i) => {
          return (
            <Item
              getStyles={getStyles}
              guideItem={child}
              index={i}
              key={`item-${i}`}
              onClick={onItemClick}
              isMatrix
            />
          );
        })}
      </div>
    </div>
  );
};

Matrix.propTypes = {
  containerItem: PropTypes.object.isRequired,
  selectedContentId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  getStyles: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  isInterestSelector: PropTypes.bool,
};

export default Matrix;
