/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { getCssStyle } from '../../../utils/getCssStyle';

/**
 * This is effectively a React component that surrounds the existing
 * `src/assets/img/shared/chevron-right.svg` asset. This React component is a special case one,
 * which we can use to render inline svg. However, for most cases, we should use the
 * `chevron-right.svg` asset. In other cases, such as showing the chevron-right svg in the mobile
 * upsell, rendering this inline svg is preferable.
 */
const ChevronRight = ({
  className,
  style,
  width = '14px',
  height = '22px',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 2 20 20"
    fill={getCssStyle('--text-color')}
    className={className}
    style={style}
  >
    <path
      d="M5.11,2.45,3,4.58a.48.48,0,0,0,0,.7L9.71,12,3,18.72a.48.48,0,0,0,0,.7l2.12,2.13a.51.51,0,0,0,.71,0L15,12.35a.48.48,0,0,0,0-.7L5.82,2.45A.51.51,0,0,0,5.11,2.45Z"
      transform="translate(1, 0)"
    />
  </svg>
);

ChevronRight.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default ChevronRight;
