import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { isXSmall } from 'src/common/utils/breakpoints';
import ContainerTitle from '../shared/ContainerTitle';
import Carousel from './Carousel';
import css from './gallery.module.scss';
import getNumberOfPages from './utils/getNumberOfPages';

// exported for testing
export const pageSize = 6;

export default class Gallery extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    breakpoint: PropTypes.number.isRequired,
    containerItem: PropTypes.object.isRequired,
    getStyles: PropTypes.func.isRequired,
    enableHorizontalScroll: PropTypes.bool,
  };

  state = {
    page: 0,
    pages: this.getNumOfPages(),
  };

  componentDidUpdate(prevProps) {
    const { breakpoint } = this.props;
    if (isXSmall(prevProps.breakpoint) !== isXSmall(breakpoint)) {
      const pages = this.getNumOfPages();
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ page: 0, pages });
    } else if (prevProps.breakpoint !== breakpoint) {
      const pages = this.getNumOfPages();
      if (pages !== this.state.pages) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ pages });
      }
    }
  }

  getNumOfPages() {
    const { containerItem, breakpoint, getStyles } = this.props;
    return getNumberOfPages(
      containerItem.children,
      pageSize,
      breakpoint,
      getStyles,
      this.rowCount,
    );
  }

  get rowCount() {
    return Number.parseInt(
      get(this.props.containerItem, 'presentation.rowCount', 1),
      10,
    );
  }

  handlePageLeft = () => {
    const prevPage = this.state.page - 1;
    this.setState({ page: prevPage < 0 ? 0 : prevPage });
  };

  handlePageRight = () => {
    const { pages, page } = this.state;
    const totalPageIndex = pages - 1;
    const nextPage = page + 1;
    this.setState({
      page: nextPage > totalPageIndex ? totalPageIndex : nextPage,
    });
  };

  render() {
    const { page, pages } = this.state;
    const { id, containerItem, breakpoint, getStyles, enableHorizontalScroll } =
      this.props;

    const showButtons =
      !enableHorizontalScroll && isXSmall(this.props.breakpoint);
    const isLeftButtonDisabled = page === 0;
    const isRightButtonDisabled = page >= pages - 1;
    const isExtendedInfoEnabled = get(
      containerItem,
      'presentation.extendedInfoEnabled',
      false,
    );

    return (
      <div
        id={id}
        data-testid={id}
        data-testtype="gallery"
        data-testcount={containerItem.children.length}
      >
        {containerItem.title && (
          <ContainerTitle
            containerItem={containerItem}
            getStyles={getStyles}
            className={css.galleryTitle}
          />
        )}
        <Carousel
          page={page}
          numRows={this.rowCount}
          items={containerItem.children}
          getStyles={getStyles}
          breakpoint={breakpoint}
          showButtons={showButtons}
          isLeftButtonDisabled={isLeftButtonDisabled}
          isRightButtonDisabled={isRightButtonDisabled}
          isExtendedInfoEnabled={isExtendedInfoEnabled}
          handlePageLeft={this.handlePageLeft}
          handlePageRight={this.handlePageRight}
        />
      </div>
    );
  }
}
