import PropTypes from 'prop-types';
import BrickTile from './BrickTile';

const LandscapeImage = ({
  guideItem,
  getStyles,
  index,
  isExtendedInfoEnabled,
}) => (
  <BrickTile
    index={index}
    guideItem={guideItem}
    getStyles={getStyles}
    isExtendedInfoEnabled={isExtendedInfoEnabled}
    isLandscapeImage
  />
);

LandscapeImage.propTypes = {
  guideItem: PropTypes.object.isRequired,
  getStyles: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isExtendedInfoEnabled: PropTypes.bool,
};

export default LandscapeImage;
