import classNames from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectIsFord } from '../../../selectors/app';
import { isXSmall } from '../../../utils/breakpoints';
import isDesktop from '../../../utils/desktop/isDesktop';
import isBottomTitleVisible from '../../../utils/guideItem/isBottomTitleVisible';
import ScrollReset from '../../shared/ScrollReset';
import CarouselItems from './CarouselItems';
import css from './gallery.module.scss';

export default function Carousel({
  showButtons,
  isLeftButtonDisabled,
  isRightButtonDisabled,
  handlePageLeft,
  handlePageRight,
  items,
  getStyles,
  numRows,
  page,
  breakpoint,
  isExtendedInfoEnabled,
}) {
  const firstGuideItem = items[0];
  const isOnDesktop = isDesktop();
  const addNoTitleStyle = !isBottomTitleVisible(firstGuideItem, getStyles);
  const isFord = useSelector(selectIsFord); // Temporary solution to enable carousel peek for Ford

  return (
    <div className={css.relative} data-testid="containerGuideItemsContainer">
      {showButtons && (
        <div
          data-testid="containerLoadMoreLeftLink"
          onClick={handlePageLeft}
          onKeyDown={(event) => {
            // Trigger handlePageLeft only for 'Enter' or 'Space' key
            if (event.key === 'Enter' || event.key === ' ') {
              handlePageLeft();
            }
          }}
          /* biome-ignore lint/a11y/useSemanticElements: TODO: explore changing div to button */
          role="button" // A11y
          tabIndex={0} // A11y
          className={classNames(css.pager, css.left, {
            [css.desktopPager]: isOnDesktop,
            [css.hide]: isLeftButtonDisabled,
            [css.noBottomTile]: addNoTitleStyle,
          })}
        >
          <i
            className={classNames(css.chevronLeft, css.chevron)}
            disabled={isLeftButtonDisabled}
          />
        </div>
      )}
      <ScrollReset
        scrollHash={isXSmall(breakpoint)}
        className={classNames(css.container, {
          [css.enableHorizontalScroll]: !showButtons,
        })}
      >
        <CarouselItems
          items={items}
          numRows={numRows}
          getStyles={getStyles}
          className={css.pad}
          page={page}
          breakpoint={breakpoint}
          isExtendedInfoEnabled={isExtendedInfoEnabled}
          showPeek={isFord}
        />
      </ScrollReset>
      {showButtons && (
        <div
          data-testid="containerLoadMoreRightLink"
          onClick={handlePageRight}
          onKeyDown={(event) => {
            // Trigger handlePageRight only for 'Enter' or 'Space' key
            if (event.key === 'Enter' || event.key === ' ') {
              handlePageRight();
            }
          }}
          /* biome-ignore lint/a11y/useSemanticElements: TODO: explore changing div to button */
          role="button" // A11y
          tabIndex={0} // A11y
          className={classNames(css.pager, css.right, {
            [css.desktopPager]: isOnDesktop,
            [css.hide]: isRightButtonDisabled,
            [css.noBottomTile]: addNoTitleStyle,
          })}
        >
          <i
            className={classNames(css.chevronRight, css.chevron)}
            disabled={isRightButtonDisabled}
          />
        </div>
      )}
    </div>
  );
}

Carousel.propTypes = {
  numRows: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  breakpoint: PropTypes.number.isRequired,
  showButtons: PropTypes.bool.isRequired,
  isLeftButtonDisabled: PropTypes.bool.isRequired,
  isRightButtonDisabled: PropTypes.bool.isRequired,
  isExtendedInfoEnabled: PropTypes.bool,
  handlePageLeft: PropTypes.func.isRequired,
  handlePageRight: PropTypes.func.isRequired,
  getStyles: PropTypes.func,
};

Carousel.defaultProps = {
  getStyles: () => ({}),
  numRows: 1,
};
