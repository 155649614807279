import get from 'lodash/get';
import PropTypes from 'prop-types';
import vars from '../../../styles/variables';
import { validateAndGetHexColor } from '../../../utils/color';
import ClampText from '../../shared/ClampText';
import ImageWithDefault from '../../shared/ImageWithDefault';
import GuideItemLink from '../shared/GuideItemLink';
import WithContentImpressionReporting from '../shared/WithContentImpressionReporting';
import css from './wideTextTile.module.scss';

const flexVertMap = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
};

const textAlignMap = {
  left: 'left',
  center: 'center',
  right: 'right',
};

const WideTextTile = ({ guideItem, index, getStyles }) => {
  const bgImage = get(guideItem, 'properties.backgroundImage.imageUrl');
  const itemStyleName = get(guideItem, 'style');
  const styles = getStyles(itemStyleName);
  const colorFromStyles = styles.textColor;
  const colorPalette = styles.textColorPalette;
  let textColor;

  if (colorFromStyles) {
    textColor = validateAndGetHexColor(styles.textColor, vars['--white']);
  } else if (colorPalette) {
    const colorIndex = index % colorPalette.length;
    textColor = validateAndGetHexColor(
      colorPalette[colorIndex],
      vars['--white'],
    );
  } else {
    textColor = vars['--white'];
  }

  const horizontalAlignment =
    textAlignMap[styles.tileTitleAlignment] || textAlignMap.left;
  const verticalAlignment =
    flexVertMap[styles.tileTitleVerticalAlignment] || flexVertMap.bottom;

  return (
    <GuideItemLink
      className={css.wideTextTile}
      guideItem={guideItem}
      dataTestId="wideTextTile"
    >
      <WithContentImpressionReporting guideItem={guideItem}>
        <ImageWithDefault
          dataTestId="guideItemImage"
          src={bgImage}
          alt={guideItem?.accessibilityTitle || guideItem?.title}
          className={css.image}
        />
        <div
          style={{
            justifyContent: verticalAlignment,
          }}
          className={css.titleWrapper}
        >
          <div
            style={{ color: textColor, textAlign: horizontalAlignment }}
            className={css.clampTextWrapper}
          >
            <ClampText clamp={2} className={css.title}>
              {guideItem.title}
            </ClampText>
          </div>
        </div>
      </WithContentImpressionReporting>
    </GuideItemLink>
  );
};

WideTextTile.propTypes = {
  guideItem: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  getStyles: PropTypes.func.isRequired,
};

export default WideTextTile;
