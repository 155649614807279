import { isMedium, isSmall } from '../../../../utils/breakpoints';

export default function getNumberOfColumns(
  requiredSlots,
  breakpoint,
  tileSize,
) {
  switch (tileSize) {
    case 'Regular': // is actually mini
      return isMedium(breakpoint) ? 0.6 : 1.2;
    case 'X-Large':
      return isMedium(breakpoint) ? 1.5 : 3;
    default:
  }

  // NOTE: requiredSlots represents the number of 'grid' slots the element needs to take up.
  switch (requiredSlots) {
    case 1:
      return isMedium(breakpoint) ? 1 : 2; // 1 is the normal tile size, 16.6%
    case 2:
      if (isMedium(breakpoint)) return 2;
      if (isSmall(breakpoint)) return 3;
      return 4;
    case 3:
      return isMedium(breakpoint) ? 3 : 6;
    default:
      throw new Error('requiredSlots out of bounds');
  }
}
