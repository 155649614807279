import classNames from 'clsx';
import get from 'lodash/get';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import containerItemsCss from '../container-items.module.scss';
import ContainerTitle from '../shared/ContainerTitle';
import TagCell from './TagCell';
import ToggleTagCell from './ToggleTagCell';

import css from './flow.module.scss';

const presentationMap = {
  ToggleTag: ToggleTagCell,
};

const getPresentation = (layout) => presentationMap[layout] || TagCell;

/**
 * Display a list of tags and make the container responsive
 * @param {object} containerItem - Object that contains all details needed to render
 * the container type data
 * @param {number} id
 * @param {function} getStyles - Function to derive the style to be applied
 * @param {string} className - Class to be applied
 * @param {function} onClick - Event handler for clicking on a tag
 * @param {function} isSelected - Function that determines if tag is selected
 */
const Flow = ({
  containerItem,
  id,
  getStyles,
  className,
  onClick,
  isSelected,
}) => {
  const children = get(containerItem, 'children', []);
  const containerSizeStyle = getStyles(containerItem.style).containerSize;

  return (
    <div
      id={id}
      data-testid={id}
      data-testtype="flowLayout"
      data-testcount={children.length}
    >
      <ContainerTitle
        containerItem={containerItem}
        getStyles={getStyles}
        className={containerItemsCss.contentSubHeader}
      />
      <div
        data-testid="containerGuideItemsContainer"
        className={classNames(
          css.flowContainer,
          className,
          css[containerSizeStyle],
        )}
      >
        {children.map((child, i) => {
          const presentationLayout = get(child, 'presentation.layout');
          const Item = getPresentation(presentationLayout);
          return (
            <Item
              guideItem={child}
              index={i}
              isSelected={isSelected(child.guideId)}
              key={`tagCell-${i}`}
              onClick={onClick}
              isDisabled={i !== 0 && isSelected(children[0].guideId)}
            />
          );
        })}
      </div>
    </div>
  );
};

Flow.propTypes = {
  containerItem: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  getStyles: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string,
  isSelected: PropTypes.func.isRequired,
};

Flow.defaultProps = {
  isSelected: noop,
};

export default Flow;
