import classNames from 'clsx';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { selectIsDiscord } from 'src/common/selectors/app';
import {
  SEE_LESS,
  SEE_MORE,
} from '../../../constants/localizations/containerItems';
import withCollapsibleState from '../../../decorators/withCollapsibleState';
import CollapsibleLabel from '../../shared/CollapsibleLabel';
import Divider from '../../shared/Divider';
import sharedCss from '../shared/shared-card.module.scss';
import ScheduleItem from './childPresentations/ScheduleItem';
import css from './schedule-card.module.scss';

// exported for testing
export const ScheduleCard = ({
  containerItem,
  id,
  shouldShowMore,
  handleMoreClick,
}) => {
  const isDiscord = useSelector(selectIsDiscord);
  const children = get(containerItem, 'children', []);
  const shouldShowMoreButton = children.length > 5;
  const numChildrenToRender = shouldShowMore ? 12 : 5;
  const childrenToRender = children.slice(0, numChildrenToRender);

  return (
    <div
      className={classNames(css.scheduleCardContainer, {
        [css.isDiscord]: isDiscord,
      })}
      id={id}
      data-testid={id}
      data-testtype="scheduleCard"
      data-testcount={childrenToRender ? childrenToRender.length : 0}
    >
      <div data-testid="containerGuideItemsContainer">
        {childrenToRender.map((child, i) => (
          <Fragment key={`scheduleItem-${i}`}>
            <ScheduleItem guideItem={child} index={i} isDiscord={isDiscord} />
            {(i !== childrenToRender.length - 1 || shouldShowMoreButton) && (
              <Divider className={css.divider} />
            )}
          </Fragment>
        ))}
        {shouldShowMoreButton && (
          <div className={css.collapsibleLabelButtonContainer}>
            <CollapsibleLabel
              onMoreInfoClick={handleMoreClick}
              shouldShowMore={shouldShowMore}
              moreText={SEE_MORE}
              lessText={SEE_LESS}
              className={classNames(sharedCss.collapsibleLabel, {
                [sharedCss.isDiscord]: isDiscord,
              })}
            />
          </div>
        )}
      </div>
    </div>
  );
};

ScheduleCard.propTypes = {
  containerItem: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  shouldShowMore: PropTypes.bool.isRequired,
  handleMoreClick: PropTypes.func.isRequired,
};

export default withCollapsibleState(ScheduleCard);
