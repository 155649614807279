import classNames from 'clsx';
import propTypes from 'prop-types';
import css from './divider.module.scss';

const Divider = ({ className, ...props }) => (
  <hr {...props} className={classNames(css.divider, className)} />
);

Divider.propTypes = {
  className: propTypes.string,
};

export default Divider;
