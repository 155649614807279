import classNames from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { openShareDialog } from '../../../actions/dialog';
import ShareIcon from '../svgIcons/player/ShareIcon';
import css from './share-menu-option.module.scss';
import sharedCss from './shared-menu-option.module.scss';

export const ShareMenuOption = ({ actions, guideItem, title, className }) => (
  <div
    data-testid="shareMenuOptionWrapper"
    className={className}
    onClick={() => actions.openShareDialog(guideItem)}
  >
    <p>{title}</p>
    <ShareIcon className={classNames(sharedCss.iconClassName, css.shareIcon)} />
  </div>
);

ShareMenuOption.propTypes = {
  guideItem: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openShareDialog,
      },
      dispatch,
    ),
  };
}

export default connect(null, mapDispatchToProps)(ShareMenuOption);
