import PropTypes from 'prop-types';
import cssVars from 'src/common/styles/variables';

/* eslint-disable max-len */
const ArrowRight = ({
  width = '30px',
  height = '30px',
  viewBox = '0 0 30 30',
  fill = cssVars['--secondary-color-5'],
  onClick,
  className,
  dataTestId,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    data-testid={dataTestId}
    onClick={onClick}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8232 17.2589L16.1762 15.0001L13.8232 12.7413"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M23 7H7V23H23V7Z"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

ArrowRight.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
  fill: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  onClick: PropTypes.func,
};

export default ArrowRight;
