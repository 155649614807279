import get from 'lodash/get';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import TagCell from './TagCell';

const ToggleTagCell = ({
  guideItem,
  index,
  onClick,
  isDisabled,
  isSelected,
}) => {
  const handleItemClick = (e) => {
    e.preventDefault();
    if (!isDisabled) {
      onClick(get(guideItem, 'guideId'));
    }
  };

  useEffect(() => {
    if (isSelected && isDisabled) {
      onClick(guideItem.guideId);
    }
  }, [guideItem.guideId, isDisabled, isSelected, onClick]);

  return (
    <TagCell
      index={index}
      dataTestType="toggleTagCell"
      guideItem={guideItem}
      onClick={handleItemClick}
      isToggleCell
      isDisabled={isDisabled}
      isSelected={isSelected}
    />
  );
};

ToggleTagCell.propTypes = {
  guideItem: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

ToggleTagCell.defaultProps = {
  onClick: noop,
};

export default ToggleTagCell;
