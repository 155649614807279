import classNames from 'clsx';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { CIRCLE } from 'src/common/constants/tile';
import { playbackStatusIcons } from 'src/common/utils/playbackStatusIconMap';
import { selectIsFord } from '../../../../selectors/app';
import commonCss from '../../../../styles/common.module.scss';
import assetUrl from '../../../../utils/assetUrl';
import { isDiscordMode } from '../../../../utils/discord';
import isSelectable from '../../../../utils/guideItem/isSelectable';
import { programTypes } from '../../../../utils/guideItemTypes';
import ClampText from '../../../shared/ClampText';
import ImageWithDefault from '../../../shared/ImageWithDefault';
import GuideItemTitle from '../../shared/GuideItemTitle';
import WithGuideItemActions from '../../shared/WithGuideItemActions';
import css from '../../shared/guide-item.module.scss';
import tileCss from './tile.module.scss';

const Tile = ({
  guideItem,
  index,
  getStyles,
  onClick,
  isMatrix,
  tileWidthAsNumber,
}) => {
  const [isSelected, setIsSelected] = useState(
    guideItem?.isSelectedInterest || false,
  );

  const isFord = useSelector(selectIsFord);
  const { playbackStatus, contentInfo } = guideItem.properties || {};
  const isSelectableItem = isSelectable(guideItem);
  const playbackStatusIcon = playbackStatus?.statusIcon;
  const isNewPlaybackItem =
    playbackStatusIcon === playbackStatusIcons.newPlaybackItem;
  const { tileSize, tileTitle, tileShape, tileTitleAlignment } = getStyles(
    guideItem.style,
  );

  const squareTileOverlayClassNames = classNames({
    [tileCss.selectableOverlay]: isSelectableItem,
    [commonCss.overlay]: !isSelectableItem,
    [tileCss.matrixOverlay]: isMatrix,
  });

  const dotClassNames = classNames(css.dot, {
    [css[`dot${tileSize}`]]: tileSize,
  });

  const dogEarClassNames = classNames(css.dogEar, {
    [css[`dogEar${tileSize}`]]: tileSize,
  });

  const captionContainerClassNames = classNames(tileCss.captionContainer, {
    [tileCss[`textAlign${tileTitleAlignment}`]]: tileTitleAlignment,
  });

  const clampTextClassNames = classNames(css.guideItemTitleMultiLine, {
    [tileCss.selectableGuideItemTitle]: isSelectableItem,
  });
  const badgeImageClassNames = classNames(css.badgeImage, {
    [css[`badgeImage${tileSize}`]]: tileSize,
  });

  const applyBadge = (url, alt) => (
    <img className={badgeImageClassNames} src={assetUrl(url)} alt={alt} />
  );

  function handleClick() {
    onClick(guideItem.guideId);

    if (isSelectableItem) {
      setIsSelected((_isSelected) => !_isSelected);
    }
  }

  function handleKeyDown(event) {
    if (event.key === 'Enter' || event.key === ' ') {
      handleClick();
    }
  }

  return (
    <div
      data-testid={`squareTileOverlay guideItem-${index} guideItemImageWrapper`}
      data-testtype="squareTile"
      className={classNames(`${squareTileOverlayClassNames}`, {
        [tileCss.tileContainer]: !isMatrix,
      })}
      onClick={handleClick}
      onKeyDown={handleKeyDown} // Add keydown event listener
      /* biome-ignore lint/a11y/useSemanticElements: TODO: explore changing div to button */
      role="button" // Add role attribute
      tabIndex={0} // Add tabIndex attribute
    >
      <WithGuideItemActions
        guideItem={guideItem}
        tileSize={tileSize}
        tileShape={tileShape}
        isSelected={isSelected}
        playButtonStyles={
          isMatrix
            ? {
                height: 'auto',
              }
            : {}
        }
        style={
          tileShape !== CIRCLE && tileWidthAsNumber < 9
            ? {
                borderRadius: '12px',
              }
            : {}
        }
      >
        {isNewPlaybackItem && (
          <div
            data-testid="newPlayableItemContainer"
            className={css.newPlayableItemContainer}
          >
            <div
              data-testid="newPlayableItemIndicator"
              className={dotClassNames}
            />
            <div className={dogEarClassNames} />
          </div>
        )}
        {!isFord &&
          contentInfo?.isPremium &&
          contentInfo?.type === programTypes.audiobook &&
          applyBadge(
            'assets/img/shared/premium-badge.svg',
            'Premium Station Icon',
          )}
        {/* The Discord check can be removed when PLATFORM-17505 is resolved */}
        {!contentInfo?.isPremium &&
          contentInfo?.isBoostStation &&
          !isDiscordMode() &&
          applyBadge('assets/img/shared/boost-badge.svg', 'Boost Station Icon')}
        <ImageWithDefault
          dataTestId="guideItemImage"
          src={guideItem.image}
          alt={guideItem?.accessibilityTitle || guideItem?.title}
          className={tileCss.image}
        />
      </WithGuideItemActions>
      {guideItem.title && tileTitle !== false && (
        <GuideItemTitle
          dataTestId="guideItemTitleLink guideItemTitle"
          guideItem={guideItem}
          hasOwnTitleWrapper
          hasImage
          className={captionContainerClassNames}
        >
          <ClampText clamp={2} className={clampTextClassNames}>
            {guideItem.title}
          </ClampText>
        </GuideItemTitle>
      )}
    </div>
  );
};

Tile.propTypes = {
  guideItem: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  getStyles: PropTypes.func,
  onClick: PropTypes.func,
  isMatrix: PropTypes.bool,
  tileWidthAsNumber: PropTypes.number,
};

Tile.defaultProps = {
  getStyles: () => ({}),
  onClick: () => {},
};

export default Tile;
