import get from 'lodash/get';
import childPresentations from '../../components/containerItems/constants/childPresentations';

const { LandscapeImage, BrickTile, Cell } = childPresentations;

export default function isBottomTitleVisible(guideItem, getStyles) {
  const title = guideItem.title;
  const { titleVisibleStyle } = getStyles(guideItem.style);
  const layout = get(guideItem, 'presentation.layout');
  // the layouts defined below do not have titles that render below the tile of the item component
  const doesLayoutSupportBottomTitle = ![
    LandscapeImage,
    BrickTile,
    Cell,
  ].includes(layout);
  return doesLayoutSupportBottomTitle && !!title && titleVisibleStyle !== false;
}
