export default function (items = [], rowCount = 1) {
  const colCount = Math.ceil(items.length / rowCount);
  const matrix = [];
  let nextIndex = 0;

  for (let rowNum = 0; rowNum < rowCount; rowNum++) {
    matrix.push([]);
  }

  for (let colNum = 0; colNum < colCount; colNum++) {
    for (let rowNum = 0; rowNum < rowCount; rowNum++) {
      if (nextIndex === items.length) {
        break;
      }

      matrix[rowNum][colNum] = items[nextIndex++];
    }
  }

  return matrix;
}
