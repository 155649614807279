import { parseBoolean } from '@tunein/web-utils';
import mapValues from 'lodash/mapValues';
import memoize from 'lodash/memoize';
import camelCase from '../../../utils/string/camelCase';

export default (styles, fallBackStyleKey = '') =>
  memoize((styleKey = '') =>
    mapValues(
      styles[camelCase(styleKey)] || styles[camelCase(fallBackStyleKey)] || {},
      parseBoolean,
    ),
  );
