import classNames from 'clsx';
import PropTypes from 'prop-types';
import ClampText from '../../shared/ClampText';
import GuideItemLink from '../shared/GuideItemLink';
import css from './tagCell.module.scss';

const TagCellComponent = ({ title }) => (
  <ClampText
    clamp={1}
    className={css.tagCell}
    truncationHTML={'<span>...</span>'}
  >
    {title}
  </ClampText>
);

TagCellComponent.propTypes = {
  title: PropTypes.string.isRequired,
};

const TagCell = ({
  guideItem,
  index,
  onClick,
  isToggleCell,
  dataTestType,
  isDisabled,
  isSelected,
}) => (
  <div
    className={classNames(css.tagCellContainer, { [css.disabled]: isDisabled })}
    data-testid={`tag-${index}`}
    data-testtype={dataTestType}
    onClick={onClick}
  >
    {isToggleCell ? (
      <div
        className={classNames(css.linkContainer, css.toggleTagCell, {
          [css.toggleSelected]: isSelected,
        })}
      >
        <TagCellComponent title={guideItem.title} />
      </div>
    ) : (
      <GuideItemLink
        className={css.linkContainer}
        guideItem={guideItem}
        dataTestId="tagCellLink"
      >
        <TagCellComponent title={guideItem?.title?.toLowerCase()} />
      </GuideItemLink>
    )}
  </div>
);

TagCell.propTypes = {
  guideItem: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  dataTestType: PropTypes.string.isRequired,
  isToggleCell: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
};

TagCell.defaultProps = {
  dataTestType: 'tagCell',
  guideItem: {},
};

export default TagCell;
