import _camelCase from 'lodash/camelCase';
import memoize from 'lodash/memoize';

function camelCase(string) {
  if (typeof string !== 'string') {
    return string;
  }

  return _camelCase(string);
}

export default memoize(camelCase);
