import classNames from 'clsx';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SEE_MORE } from 'src/common/constants/localizations/containerItems';
import { behaviors as GuideItemBehaviors } from 'src/common/utils/guideItemTypes';
import { fetchMoreItems } from '../../../actions/moreItems';
import { LocationAndLocalizationContext } from '../../../providers/LocationAndLocalizationProvider';
import css from './load-more-items.module.scss';

const limit = 30;

// This is due to favorites using an object to store UI data (see mapFavorites.js),
//  while other pages use the array format we get from the API without mapping.
const keyType = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
]).isRequired;

// exported for testing
export class LoadMoreItems extends Component {
  static propTypes = {
    index: keyType,
    containerNavigation: PropTypes.object.isRequired,
    offset: PropTypes.number.isRequired,
    actions: PropTypes.object.isRequired,
    selectedContentId: PropTypes.string.isRequired,
    containerItem: PropTypes.object,
  };

  static contextType = LocationAndLocalizationContext;

  handleClickAction = () => {
    const {
      actions,
      containerNavigation,
      index,
      selectedContentId,
      offset,
      containerItem,
    } = this.props;

    actions.fetchMoreItems({
      selectedContentId,
      containerNavigation,
      containerIndex: index,
      offset,
      limit,
      containerItem,
    });
  };

  render() {
    const { containerNavigation } = this.props;

    if (
      isEmpty(containerNavigation) ||
      isEmpty(containerNavigation.destinationInfo) ||
      containerNavigation.type === GuideItemBehaviors.browse
    ) {
      return null;
    }

    return (
      <div className={classNames('row', css.container)}>
        <div className="col-xs-12">
          <a
            /* biome-ignore lint/a11y/useSemanticElements: TODO: explore changing to input or button */
            role="button"
            tabIndex="0"
            data-testid="containerLoadMoreLink"
            className={css.link}
            onClick={this.handleClickAction}
          >
            {this.context.getLocalizedText(SEE_MORE)}
          </a>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  const actions = {
    fetchMoreItems,
  };

  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(LoadMoreItems);
