import classNames from 'clsx';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import withOutsideClickControl from '../../../../decorators/withOutsideClickControl';
import Popover from '../../../shared/Popover';
import FavoriteMenuOption from '../../../shared/menu/FavoriteMenuOption';
import ProfileNavMenuOption from '../../../shared/menu/ProfileNavMenuOption';
import ShareMenuOption from '../../../shared/menu/ShareMenuOption';
import sharedCss from '../../../shared/menu/shared-menu-option.module.scss';
import css from './schedule-card-actions.module.scss';

const menuOptionMap = {
  Follow: FavoriteMenuOption,
  Share: ShareMenuOption,
  Profile: ProfileNavMenuOption,
};

const getMenuOption = (item) => menuOptionMap[item];

// exported for testing
export const ScheduleCardActions = (props) => {
  const {
    guideItem = {},
    isMobile,
    isOpen,
    handleOnClick,
    containerRef,
  } = props;

  const defaultAction = get(guideItem, 'behaviors.default.actionName');
  const menuItems =
    defaultAction === 'Menu' && get(guideItem, 'actions.menu.items');

  if (!menuItems) {
    return null;
  }

  return (
    <div className={css.container} ref={containerRef}>
      <div className={css.menu} onClick={handleOnClick}>
        <div className={css.dot} />
      </div>
      <Popover
        isOpen={isOpen}
        className={css.popover}
        contentClassName={css.popoverContent}
        pointerClassName={css.popoverPointer}
      >
        {menuItems.map((menuItem, index) => {
          const actionKey = get(menuItem, 'actionKey');
          const MenuOption = getMenuOption(actionKey);
          if (!MenuOption) {
            return null;
          }
          const menuAction = get(guideItem, [
            'actions',
            actionKey.toLowerCase(),
          ]);
          const actionTitle = get(menuItem, 'title');
          return (
            <MenuOption
              action={menuAction}
              guideItem={guideItem}
              isMobile={isMobile}
              title={actionTitle}
              key={actionKey}
              className={classNames(sharedCss.menuOption, {
                [sharedCss.isFirstOption]: index === 0,
                [sharedCss.isLastOption]: index === menuItems.length - 1,
              })}
            />
          );
        })}
      </Popover>
    </div>
  );
};

ScheduleCardActions.propTypes = {
  guideItem: PropTypes.object.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  containerRef: PropTypes.object.isRequired,
};

export default withOutsideClickControl(ScheduleCardActions, true);
