import { isTopic } from '@tunein/web-common';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Component } from 'react';
import css from './gallery.module.scss';
import getGalleryItem from './utils/getGalleryItem';
import getNumberOfColumns from './utils/getNumberOfColumns';
import sortGalleryItems from './utils/sortGalleryItems';

const gridPctUnit = 100 / 6;
const showPeekTileWidthsBySlotCountMap = {
  1: 19,
  2: 32,
  3: 32,
};

export default class CarouselItems extends Component {
  static propTypes = {
    page: PropTypes.number.isRequired,
    items: PropTypes.array.isRequired,
    numRows: PropTypes.number.isRequired,
    getStyles: PropTypes.func.isRequired,
    breakpoint: PropTypes.number.isRequired,
    isExtendedInfoEnabled: PropTypes.bool,
    showPeek: PropTypes.bool,
    className: PropTypes.string,
  };

  render() {
    const {
      items,
      numRows,
      page,
      breakpoint,
      getStyles,
      isExtendedInfoEnabled,
      showPeek,
      className = '',
    } = this.props;
    const sortedArray = sortGalleryItems(items, numRows);

    // Extract the guide item rendering logic into a separate function
    function renderGuideItem(guideItem, itemsArrayIndex, rowIndex) {
      const { Component: ItemComponent, requiredSlots } =
        getGalleryItem(guideItem);

      const itemIndex =
        Number.parseInt(get(guideItem, 'index', itemsArrayIndex + 1), 10) - 1;
      const { tileSize } = getStyles(guideItem.style);
      const numberOfColumns = getNumberOfColumns(
        requiredSlots,
        breakpoint,
        tileSize,
      );

      const tileWidthAsNumber = showPeek
        ? showPeekTileWidthsBySlotCountMap[requiredSlots]
        : gridPctUnit * numberOfColumns;
      const tileWidth = `${tileWidthAsNumber}%`; // as a %

      // these variables below are used for automation testing
      let nextGuideId;
      let title;
      let nextTopicId;

      if (isTopic(guideItem.guideId)) {
        nextGuideId = get(
          guideItem,
          'properties.parentProgram.destinationInfo.id',
        );
        title = get(
          guideItem,
          'properties.parentProgram.destinationInfo.seoName',
        );
        nextTopicId = guideItem.guideId;
      } else {
        nextGuideId = guideItem.guideId;
        title = get(guideItem, 'properties.seoInfo.title');
      }
      return (
        <div
          data-testid={`carouselGuideItem-${itemIndex}`}
          key={`row-${rowIndex}item-${itemIndex}id-${guideItem.guideId}`}
          className={css.item}
          style={{ width: tileWidth }}
          data-nexttitle={title}
          data-nextguideitem={nextGuideId}
          data-nexttopicid={nextTopicId}
        >
          <ItemComponent
            tileWidthAsNumber={tileWidthAsNumber}
            guideItem={guideItem}
            index={itemIndex}
            getStyles={getStyles}
            isExtendedInfoEnabled={isExtendedInfoEnabled}
            containerClassName={css.carouselItemContainer}
          />
        </div>
      );
    }

    return (
      <div
        data-testid="carousel"
        className={`${className} ${css.carousel} ${
          numRows === 1 ? css.row : ''
        }`}
        style={{ left: `-${page * 100}%` }}
      >
        {sortedArray.map((itemsArray, rowIndex) =>
          numRows > 1 ? (
            <div
              key={`row-${rowIndex}`}
              className={`${css.row} ${css.carouselRow}`}
            >
              {itemsArray.map((guideItem, itemsArrayIndex) =>
                renderGuideItem(guideItem, itemsArrayIndex, rowIndex),
              )}
            </div>
          ) : (
            itemsArray.map((guideItem, itemsArrayIndex) =>
              renderGuideItem(guideItem, itemsArrayIndex, rowIndex),
            )
          ),
        )}
      </div>
    );
  }
}
