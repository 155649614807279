import get from 'lodash/get';
import childPresentations from '../../constants/childPresentations';
import Default from '../../list/childPresentations/Default';
import BrickTile from '../childPresentations/BrickTile';
import LandscapeImage from '../childPresentations/LandscapeImage';
import Tile from '../childPresentations/Tile';

const map = {
  [childPresentations.BrickTile]: {
    requiredSlots: 2,
    Component: BrickTile,
  },
  [childPresentations.Tile]: {
    requiredSlots: 1,
    Component: Tile,
  },
  [childPresentations.LandscapeImage]: {
    requiredSlots: 3,
    Component: LandscapeImage,
  },
  [childPresentations.Cell]: {
    requiredSlots: 3,
    Component: Default,
  },
};

export default function getGalleryItem(guideItem) {
  return (
    map[get(guideItem, 'presentation.layout')] || map[childPresentations.Tile]
  );
}
