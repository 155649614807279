import classnames from 'clsx';
import PropTypes from 'prop-types';
import ClampText from '../../../shared/ClampText';
import ScheduleCardActions from './ScheduleCardActions';
import css from './schedule-item.module.scss';

// exported for testing
export const ScheduleItem = (props) => {
  const { guideItem = {}, index, isDiscord } = props;

  const { title, subtitle, guideId } = guideItem;

  return (
    <div
      className={css.scheduleItemContainer}
      data-testid={`guideItem-${index}`}
      data-testtype="scheduleItem"
      data-nextguideitem={guideId}
    >
      {subtitle && (
        <span className={css.dateTime}>{subtitle.toLowerCase()}</span>
      )}
      <ClampText
        clamp={2}
        className={classnames(css.title, { [css.titleWithoutDate]: !subtitle })}
      >
        {title}
      </ClampText>
      {!isDiscord && <ScheduleCardActions guideItem={guideItem} />}
    </div>
  );
};

ScheduleItem.propTypes = {
  guideItem: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default ScheduleItem;
