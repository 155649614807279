import classNames from 'clsx';
import PropTypes from 'prop-types';
import GuideItemLink from '../../containerItems/shared/GuideItemLink';
import ArrowRight from '../svgIcons/ArrowRightIcon';
import css from './profile-nav-menu-option.module.scss';
import sharedCss from './shared-menu-option.module.scss';

const ProfileNavMenuOption = ({ guideItem, title, className }) => (
  <GuideItemLink
    dataTestId="goToProfileLink"
    guideItem={guideItem}
    className={className}
  >
    <p>{title}</p>
    <ArrowRight
      className={classNames(sharedCss.iconClassName, css.arrowIcon)}
    />
  </GuideItemLink>
);

ProfileNavMenuOption.propTypes = {
  guideItem: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ProfileNavMenuOption;
