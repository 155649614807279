import get from 'lodash/get';
import PropTypes from 'prop-types';
import { defaultBrickImageSrc } from '../../../../constants/imageUrls';
import commonCss from '../../../../styles/common.module.scss';
import ImageWithDefault from '../../../shared/ImageWithDefault';
import Default from '../../list/childPresentations/Default';
import WithGuideItemActions from '../../shared/WithGuideItemActions';
import css from './brickTile.module.scss';

const BrickTile = ({
  guideItem,
  getStyles,
  isLandscapeImage,
  index,
  isExtendedInfoEnabled,
}) => {
  const bannerImgSrc = isLandscapeImage
    ? get(guideItem, 'properties.landscapeImage.imageUrl')
    : get(guideItem, 'properties.brickImage.imageUrl');

  return (
    <>
      <div
        data-testid={`brickTileOverlay guideItem-${index}`}
        data-testtype="brickTile"
        className={`${commonCss.overlay} ${css.container}`}
      >
        <WithGuideItemActions guideItem={guideItem}>
          <ImageWithDefault
            dataTestId="guideItemImage"
            src={bannerImgSrc || defaultBrickImageSrc}
            alt={guideItem?.accessibilityTitle || guideItem?.title}
            className={css.image}
            placholderClassName={css.imagePlaceholder}
          />
        </WithGuideItemActions>
      </div>
      {isExtendedInfoEnabled && (
        <Default
          data-testid="extendedInfo"
          isExtendedInfoView
          guideItem={guideItem}
          getStyles={getStyles}
          index={0}
          className={css.container}
          containerClassName={css.guideItemContainer}
          imageWrapperClassName={css.guideItemImageWrapper}
        />
      )}
    </>
  );
};

BrickTile.propTypes = {
  guideItem: PropTypes.object.isRequired,
  getStyles: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isLandscapeImage: PropTypes.bool,
  isExtendedInfoEnabled: PropTypes.bool,
};

export default BrickTile;
