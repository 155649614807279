import PropTypes from 'prop-types';
import FavoriteOption from '../FavoriteOption';
import FavoriteStyleContext from '../controls/favorite/context/FavoriteStyleContext';
import css from './favorite-menu-option.module.scss';
import sharedCss from './shared-menu-option.module.scss';

// exported for testing
const FavoriteMenuOption = ({ action, guideItem, className }) => {
  const guideId = guideItem?.guideId;

  return (
    <FavoriteStyleContext.Provider
      value={{
        iconClassName: sharedCss.iconClassName,
        unFavoritedClassName: css.unFavoritedIcon,
      }}
    >
      <FavoriteOption
        guideId={guideId}
        actionOrFollowOption={action}
        className={className}
        isContainerItemFavorite
      />
    </FavoriteStyleContext.Provider>
  );
};

FavoriteMenuOption.propTypes = {
  guideItem: PropTypes.object.isRequired,
  action: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default FavoriteMenuOption;
