import getGalleryItem from './getGalleryItem';
import getNumberOfColumns from './getNumberOfColumns';

export default function getNumberOfPages(
  items,
  pageSize,
  breakpoint,
  getStyles,
  rowCount = 1,
) {
  const firstRow = items.slice(0, Math.ceil(items.length / rowCount));

  const slotSum = firstRow.reduce((sum, item) => {
    const { requiredSlots } = getGalleryItem(item);
    const { tileSize } = getStyles(item.style);

    return sum + getNumberOfColumns(requiredSlots, breakpoint, tileSize);
  }, 0);

  return slotSum / pageSize;
}
